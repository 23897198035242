var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card p-2",attrs:{"id":"page-product"}},[_c('div',{staticClass:"mt-2"},[_c('validation-observer',{ref:"simpleRules"},[_c('div',[_c('h1',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"mt-2"},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome*","label-for":"input-name"}},[_c('b-form-input',{attrs:{"id":"input-name","maxlength":"20"},model:{value:(_vm.productForm.name),callback:function ($$v) {_vm.$set(_vm.productForm, "name", $$v)},expression:"productForm.name"}})],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é obrigatório. ")]):_vm._e(),(_vm.productErrorApiForm.name)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.productErrorApiForm.name)+" ")]):_vm._e()]}}])})],1),_c('div',{staticClass:"mt-2"},[_c('validation-provider',{attrs:{"name":"textarea","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descrição*","label-for":"textarea-description"}},[_c('b-form-textarea',{attrs:{"id":"textarea-description","rows":"2","maxlength":"115"},model:{value:(_vm.productForm.description),callback:function ($$v) {_vm.$set(_vm.productForm, "description", $$v)},expression:"productForm.description"}})],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é obrigatório. ")]):_vm._e(),(_vm.productErrorApiForm.description)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.productErrorApiForm.description)+" ")]):_vm._e()]}}])})],1),_c('div',{staticClass:"mt-2"},[_c('validation-provider',{attrs:{"name":"linkInformation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Link para página de informações*","label-for":"input-link-information"}},[_c('b-form-input',{staticClass:"customLink",attrs:{"id":"input-link-information","maxlength":"255"},model:{value:(_vm.productForm.informationLink),callback:function ($$v) {_vm.$set(_vm.productForm, "informationLink", $$v)},expression:"productForm.informationLink"}})],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é obrigatório. ")]):_vm._e(),(_vm.productErrorApiForm.informationLink)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.productErrorApiForm.informationLink)+" ")]):_vm._e()]}}])})],1),_c('div',{staticClass:"mt-2"},[_c('validation-provider',{attrs:{"name":"linkExternal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Link para sistema externo*","label-for":"input-link-external"}},[_c('b-form-input',{staticClass:"customLink",attrs:{"id":"input-link-external","maxlength":"255"},model:{value:(_vm.productForm.externalLink),callback:function ($$v) {_vm.$set(_vm.productForm, "externalLink", $$v)},expression:"productForm.externalLink"}})],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é obrigatório. ")]):_vm._e(),(_vm.productErrorApiForm.externalLink)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.productErrorApiForm.externalLink)+" ")]):_vm._e()]}}])})],1),_c('div',{staticClass:"mt-2"},[_c('validation-provider',{attrs:{"name":"file","rules":"mimes:image/png,image/jpg|size:2048"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Imagem","label-for":"input-image"}},[_c('div',{staticClass:"w-100"},[_c('b-form-file',{ref:"teste",staticClass:"browseTextCustom",attrs:{"id":"input-image","browse-text":!_vm.productForm.imageFile ? 'Escolher arquivo' : 'Substituir arquivo',"placeholder":_vm.filenameEdit ? _vm.filenameEdit : 'Nenhum arquivo selecionado',"drop-placeholder":"Pode soltar o arquivo aqui","accept":"image/png,image/jpg"},on:{"input":function () { return _vm.filenameEdit = null; }},scopedSlots:_vm._u([{key:"file-name",fn:function(ref){
var names = ref.names;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"imageFilename"},[_vm._v(" "+_vm._s(names[0])+" ")])])]}}],null,true),model:{value:(_vm.productForm.imageFile),callback:function ($$v) {_vm.$set(_vm.productForm, "imageFile", $$v)},expression:"productForm.imageFile"}}),(_vm.productForm.imageFile)?_c('div',{staticClass:"trashIcon",style:(_vm.fixedDivStyles),on:{"~click":function($event){return _vm.dropFileAndErrors.apply(null, arguments)}}},[_c('feather-icon',{attrs:{"size":"22","icon":"Trash2Icon"}})],1):_vm._e()],1),_c('label',{staticClass:"mt-1",attrs:{"for":"input-image"}},[_vm._v(" A imagem deve ser salva nos formatos JPG ou PNG e ter no máximo 2MB. Sugerimos que a imagem possua 500 pixels de largura e 400 pixels de altura. ")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" O arquivo deve ter a extensão png ou jpg e não deve passar de 2MB. ")]):_vm._e(),(_vm.productErrorApiForm.imageFile)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.productErrorApiForm.imageFile)+" ")]):_vm._e()]}}])})],1),_c('div',{staticClass:"d-flex justify-content-center align-items-center mt-2"},[_c('b-button',{staticClass:"btn-icon bt px-1 mr-1",attrs:{"disabled":_vm.loading,"variant":"outline-danger"},on:{"click":function($event){$event.preventDefault();return _vm.closeAction.apply(null, arguments)}}},[_vm._v(" Descartar ")]),_c('b-button',{staticClass:"btn-icon bt px-1 mr-1",attrs:{"disabled":_vm.loading,"variant":"custom"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[(_vm.loading)?_c('span',{staticClass:"spinner-border spinner-border-sm mr-1",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e(),_vm._v(" Salvar ")])],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }