<template>
    <div id="page-product" class="card p-2">
      <div class="mt-2">
        <validation-observer ref="simpleRules">
          <div>
            <h1 class="text-left">{{ title }}</h1>
          </div>
          <div class="mt-2">
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-group
                label="Nome*"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="productForm.name"
                  maxlength="20"
                />
              </b-form-group>
              <small v-if="errors[0]" class="text-danger">
                Este campo é obrigatório.
              </small>
              <small v-if="productErrorApiForm.name" class="text-danger">
                {{ productErrorApiForm.name }}
              </small>
            </validation-provider>
          </div>
          <div class="mt-2">
            <validation-provider
              #default="{ errors }"
              name="textarea"
              rules="required"
            >
              <b-form-group
                label="Descrição*"
                label-for="textarea-description"
              >
                <b-form-textarea
                  id="textarea-description"
                  v-model="productForm.description"
                  rows="2"
                  maxlength="115"
                />
              </b-form-group>
              <small v-if="errors[0]" class="text-danger">
                Este campo é obrigatório.
              </small>
              <small v-if="productErrorApiForm.description" class="text-danger">
                {{ productErrorApiForm.description }}
              </small>
            </validation-provider>
          </div>
          <div class="mt-2">
            <validation-provider
              #default="{ errors }"
              name="linkInformation"
              rules="required"
            >
              <b-form-group
                label="Link para página de informações*"
                label-for="input-link-information"
              >
                <b-form-input
                  id="input-link-information"
                  v-model="productForm.informationLink"
                  class="customLink"
                  maxlength="255"
                />
              </b-form-group>
              <small v-if="errors[0]" class="text-danger">
                Este campo é obrigatório.
              </small>
              <small v-if="productErrorApiForm.informationLink" class="text-danger">
                {{ productErrorApiForm.informationLink }}
              </small>
            </validation-provider>
          </div>
          <div class="mt-2">
            <validation-provider
              #default="{ errors }"
              name="linkExternal"
              rules="required"
            >
              <b-form-group
                label="Link para sistema externo*"
                label-for="input-link-external"
              >
                <b-form-input
                  id="input-link-external"
                  v-model="productForm.externalLink"
                  class="customLink"
                  maxlength="255"
                />
              </b-form-group>
              <small v-if="errors[0]" class="text-danger">
                Este campo é obrigatório.
              </small>
              <small v-if="productErrorApiForm.externalLink" class="text-danger">
                {{ productErrorApiForm.externalLink }}
              </small>
            </validation-provider>
          </div>
          <div class="mt-2">
            <validation-provider
              #default="{ errors }"
              name="file"
              rules="mimes:image/png,image/jpg|size:2048"
            >
              <b-form-group
                label="Imagem"
                label-for="input-image"
              >
                <div class="w-100">
                  <b-form-file
                    ref="teste"
                    id="input-image"
                    v-model="productForm.imageFile"
                    class="browseTextCustom"
                    :browse-text="!productForm.imageFile ? 'Escolher arquivo' : 'Substituir arquivo'"
                    :placeholder="filenameEdit ? filenameEdit : 'Nenhum arquivo selecionado'"
                    drop-placeholder="Pode soltar o arquivo aqui"
                    accept="image/png,image/jpg"
                    @input="() => filenameEdit = null"
                  >
                    <template slot="file-name" slot-scope="{ names }">
                      <div class="d-flex">
                        <div class="imageFilename">
                          {{ names[0] }}
                        </div>
                      </div>
                    </template>
                  </b-form-file>
                  <div
                    v-if="productForm.imageFile"
                    class="trashIcon"
                    :style="fixedDivStyles"
                    @click.once="dropFileAndErrors"
                  >
                    <feather-icon
                      size="22"
                      icon="Trash2Icon"
                    />
                  </div>
                </div>
                <label class="mt-1" for="input-image">
                  A imagem deve ser salva nos formatos JPG ou PNG e ter no máximo 2MB.
                  Sugerimos que a imagem possua 500 pixels de largura e 400 pixels de altura.
                </label>
              </b-form-group>
              <small v-if="errors[0]" class="text-danger">
                O arquivo deve ter a extensão png ou jpg e não deve passar de 2MB.
              </small>
              <small v-if="productErrorApiForm.imageFile" class="text-danger">
                {{ productErrorApiForm.imageFile }}
              </small>
            </validation-provider>
          </div>
          <div class="d-flex justify-content-center align-items-center mt-2">
            <b-button
              :disabled="loading"
              variant="outline-danger"
              class="btn-icon bt px-1 mr-1"
              @click.prevent="closeAction"
            >
              Descartar
            </b-button>
            <b-button
              :disabled="loading"
              variant="custom"
              class="btn-icon bt px-1 mr-1"
              @click.prevent="validationForm"
            >
              <span v-if="loading" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                Salvar
            </b-button>
          </div>
        </validation-observer>
      </div>
    </div>
  </template>
  <script>
  import { BButton, BFormInput, BFormTextarea, BFormFile, BFormGroup } from 'bootstrap-vue';
  import { ValidationObserver, ValidationProvider } from "vee-validate";
  import { mimes, size, required } from '@validations';
  import handleErrorsService from '../Services/apiErrorHandler';
  import { modalGenericModel } from '@/libs/sweetalerts'
  import $store from '@/store';

  export default {
    components: {
        BButton, BFormInput, BFormTextarea, BFormFile, BFormGroup,
        ValidationObserver, ValidationProvider
    },
    computed: {
      fixedDivStyles() {
        return {
          'margin-left': `${this.fixedDivLeftTrashIcon}px`
        };
      },
    },
    data() {
        return {
          loading: false,
          fixedDivLeftTrashIcon: 250,
          title: null,
          filenameEdit: null,
          productForm: {
            name: null,
            description: null,
            informationLink: null,
            externalLink: null,
            imageFile: null
          },
          productErrorApiForm: {
            name: null,
            description: null,
            informationLink: null,
            externalLink: null,
            imageFile: null
          },
          mimes,
          size,
          required
        }
    },
    methods: {
      validationForm() {
        this.$refs.simpleRules.validate().then(success => {
          if(success){
            this.prepareAlteration();
          }
        })
      },

      async prepareAlteration() {
        this.loading = true;
        let formData = new FormData();
        formData.append('nome', this.productForm.name);
        formData.append('descricao', this.productForm.description);
        formData.append('link_informacao', this.productForm.informationLink);
        formData.append('link_pagina', this.productForm.externalLink);
        if(this.productForm.imageFile){
          formData.append('imagem', this.productForm.imageFile);
        }
        let headers = {
          header: {
              'Content-Type' : 'multipart/form-data'
          }
        }

        await this.saveEditProduct(this.$api.OutrosProdutos.getAndSaveProduct, formData, headers);

      },

      async saveEditProduct(requisition, formData, headers){
        await this.$http.post(requisition, formData, headers)
        .then(() => {
          this.successfullyRequest();
        }).catch(({ response }) => {
          this.unsuccessfulRequest(response);
        });
      },

      closeAction(){
        this.$router.push({ name: 'sesi-listar-outros-produtos' });
      },

      modalSucesso(){
        this.$swal({
          title: 'Sucesso',
          icon: 'success',
          html: '<div>As informações foram gravadas com sucesso!</div>',
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false
        }).then(() => {
           this.$router.push({ name: 'sesi-listar-outros-produtos' });
        })
      },
      dropFileAndErrors() {
        this.productForm.imageFile = null;
        this.filenameEdit = null;
        this.productErrorApiForm.imageFile = null;
      },
      updateFixedDivPosition() {
        const windowWidth = window.innerWidth;
        if(windowWidth < 576 && windowWidth > 520){
          this.fixedDivLeftTrashIcon = Math.floor(
            280 - (575 - windowWidth)
          );
        }else if(windowWidth <= 520){
          this.fixedDivLeftTrashIcon = Math.floor(
            290 - (520 - windowWidth)
          );
        }else if(windowWidth >= 576){
          this.fixedDivLeftTrashIcon = 250;
        }
      },
      prepareReceivedImage(productName, imageArray){
        const base64Image = imageArray.imagem;
        const extension = imageArray.arquivo.split(".").pop();
        const byteCharacters = Buffer.from(base64Image.split(',')[1], 'base64');
        const byteArray = new Uint8Array(byteCharacters);
        const blob = new Blob([byteArray], { type: 'image/'+extension });
        this.filenameEdit = productName+'.'+extension;
        return new File([blob], productName+'.'+extension, { type: 'image/'+extension });
      },
      prepareLink(link){
        if(link && link.substr(0, 8) === "https://"){
          return link;
        }
        return 'https://' + (link ? link : '');
      },
      successfullyRequest(){
        this.loading = false;
        this.modalSucesso();
        this.$emit('reloadList');
        this.limparForm();
      },
      unsuccessfulRequest(response){
        this.loading = false;
        if(response?.status == 422){
          handleErrorsService.handleProductErrors(response.data, this.productErrorApiForm)
        }else{
          showGenericErrorModal();
        }
      },

      showGenericErrorModal(){
        modalGenericModel(
          'Ocorreu um erro!',
          'Ocorreu um erro de conexão com nossa base de dados. Por favor, tente novamente mais tarde ou contate nosso suporte.',
          require('@/assets/custom-icons/cora-icons/error.png'),
          'Ok'
        ).then(() => {
          this.$router.push(
            {
              name: 'bem-vindo'
            }
          );
        });
      },

      modalShowMethod() {
        this.loading = false;
        this.filenameEdit = null;
        this.title = 'Cadastrar novo produto';
        this.productForm.name = null;
        this.productForm.description = null;
        this.productForm.informationLink = this.prepareLink(null);
        this.productForm.externalLink = this.prepareLink(null);
        this.productForm.imageFile = null;
      },

      limparForm(){
        this.productForm.name = null;
        this.productForm.description = null;
        this.productForm.informationLink = null;
        this.productForm.externalLink = null;
        this.productForm.imageFile = null;

        this.productErrorApiForm.name = null;
        this.productErrorApiForm.description = null;
        this.productErrorApiForm.informationLink = null;
        this.productErrorApiForm.externalLink = null;
        this.productErrorApiForm.imageFile = null;

        this.$refs.simpleRules.reset();
      }
    },

    mounted() {
      if(this.$route.meta.cadastrar){
        $store.dispatch('outrosProdutosState/resetProduct');
      }
      window.addEventListener('resize', this.updateFixedDivPosition);
      this.updateFixedDivPosition();
      this.modalShowMethod();
    },

    beforeUnmount() {
      window.removeEventListener('resize', this.updateFixedDivPosition);
    },
  }
  </script>

  <style>
    .xButton{
      cursor: pointer;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
      border-radius: 6px;
      background: #FFFFFF;
      position: sticky;
      padding: 5px;
      margin-right: -27px;
      margin-top: -20px;
    }
    .trashIcon{
      color: #2772c0;
      cursor: pointer;
      position: sticky;
      margin-top: -30px;
      z-index: 5;
    }

    .custom-file-input{
      z-index: 2 !important;
      cursor: pointer !important;
    }
    .browseTextCustom .custom-file-label::after {
      color: #2772c0;
      border: 1px solid #2772c0;
      background-color: #F6F9FC;
      border-radius: 5px;
      height: 36px;
    }
    .imageFilename{
      white-space: nowrap;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 10px;
    }
    .customLink{
      color: blue;
      text-decoration: underline;
    }
    @media (max-width: 520px) {
      .custom-file-label::after{
        content: 'Upload' !important;
        max-width: 80px;
      }
      .imageFilename{
        max-width: 200px;
        margin-right: 5px;
      }
    }
    @media (max-width: 450px) {
      .imageFilename{
        max-width: 150px;
      }
    }
    @media (max-width: 400px) {
      .imageFilename{
        max-width: 100px;
      }
    }
    @media (max-width: 350px) {
      .imageFilename{
        max-width: 80px;
      }
    }
  </style>
